<!--
 * @Description:
 * @Author: mTm
 * @Date: 2021-11-15 09:20:45
 * @LastEditTime: 2021-11-15 09:33:58
 * @LastEditors: mTm
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
</style>
